<script lang="ts" setup>
const { isLoggedIn, logout, user } = useUser();

const loginModalController = useLoginModal();

const { formatLink } = useInternationalization();
const isAccountMenuOpen = ref(false);

async function invokeLogout() {
  await logout();
  isAccountMenuOpen.value = false;
}
</script>
<template>
  <ClientOnly>
    <div class="relative">
      <button
        class="group/link relative block bg-transparent"
        @click="isLoggedIn ? (isAccountMenuOpen = !isAccountMenuOpen) : (loginModalController.open())"
      >
        <span class="sr-only">Maas Account</span>
        <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><g class="fill-current" clip-rule="evenodd" fill-rule="evenodd"><path d="M15 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/><path d="M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0 1 11.065 13a8.983 8.983 0 0 1 7.092 3.458A9.001 9.001 0 1 0 2 11zm9 9a8.963 8.963 0 0 1-5.672-2.012A6.991 6.991 0 0 1 11.065 15a6.991 6.991 0 0 1 5.689 2.92A8.964 8.964 0 0 1 11 20z"/></g></svg>
      </button>
      <!-- Profile dropdown -->
      <Transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          :class="[isAccountMenuOpen ? 'block' : 'hidden']"
          aria-labelledby="user-menu-button"
          aria-orientation="vertical"
          class="z-20 origin-top-right absolute right-0 top-full mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          tabindex="-1"
        >
          <NuxtLink
            id="user-menu-item-1"
            :to="formatLink('/account')"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            data-testid="header-my-account-link"
            tabindex="-1"
          >
            {{ $t("account.menu.myAccount") }}
          </NuxtLink>
          <button
            id="user-menu-item-2"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            data-testid="header-sing-out-link"
            role="menuitem"
            tabindex="-2"
            @click="invokeLogout"
          >
            {{ $t("account.menu.signOut") }}
          </button>
        </div>
      </Transition>
    </div>
  </ClientOnly>
</template>
